.title {
    font-size: 1.5em;
}
.subtitle {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 13px;
}
.markdown{
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 3px;
}
.render{
    padding:5px;
}
td{
    padding-bottom: 10px;
    font-size: 1.1em;
}
