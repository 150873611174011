@import '~antd/dist/antd.css';

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  min-width: 100%;
  min-height: 100%;

}

.imgContainer {
  margin: 2%;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.image {
  width: 100%;
  pointer-events: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.imgToolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 50px;
  transition: 0.5s ease;
  opacity: 0;
  left: 0;
  padding-right: 5px;
}

.imgToolbar.top {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.4+0,0+100 */
  background: -moz-linear-gradient(
          top,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(125, 185, 232, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
          top,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(125, 185, 232, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(125, 185, 232, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
          startColorstr="#66000000",
          endColorstr="#007db9e8",
          GradientType=0
  ); /* IE6-9 */
  top: 0;
}

.imgToolbar.bottom {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.4+0,0+100 */
  background: -moz-linear-gradient(
          top,
          rgba(125, 185, 232, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
          top,
          rgba(125, 185, 232, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
          to bottom,
          rgba(125, 185, 232, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
          startColorstr="#007db9e8",
          endColorstr="#66000000",
          GradientType=0
  ); /* IE6-9 */
  bottom: 0;
}

.imgContainer:hover .imgToolbar {
  opacity: 1;
}

.entry .ant-form-explain {
  color: white !important;
}

.password-forgotten {
  color: white;
  font-size: 16px !important;
  font-weight: bold;
}

.password-forgotten:hover {
  color: #f21852;
}

.color-primary {
  color: #f21852;
}

.color-secondary {
  color: #8e8e8e;
}

.primary-link {
  color: #f21852;
}

.primary-link:hover {
  color: #f21852;
  opacity: 0.8;
}

.primary-clickable {
  color: #f21852;
  cursor: pointer;
}

.primary-clickable:hover {
  opacity: 0.8;
}

:focus {
  outline: 0;
}

.blur {
  filter: blur(20px);
}



.color-primary {
  color: #f21850;
}

.color-secondary {
  color: #82828c;
}

.clickable-primary {
  color: #f21850;
  cursor: pointer;
}

.clickable-primary:hover {
  opacity: 0.8;
}

.clickable-secondary {
  color: #82828c;
  cursor: pointer;
}

.clickable-secondary:hover {
  color: #f21850;
}

.weight-light {
  font-weight: 200;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}

.size-tiny {
  font-size: 0.75rem;
}

.size-small {
  font-size: 0.9rem;
}

.size-large {
  font-size: 1.1rem;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.horizontal-divider {
  height: 1px;
  background-color: #d8d8d8;
  margin: 20px 0px;
}

/*
MeiliSearch
*/

.modified-item:hover {
  border-bottom: 2px solid transparent !important;
  color: inherit !important;
}

/*.ais-RefinementList-count {*/
/*  display: none;*/
/*}*/

/*SearchBox */

.ais-SearchBox {
  border: 1px solid #ABABB2;
  border-radius: 8px;
  width: 297px;
}


.ais-SearchBox-submit {

}

.ais-SearchBox-input {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 16px;
}

.ais-SearchBox-input input {
  /*max-width: 390px;*/
  /*min-width: 390px;*/
  /*width: 390px;*/
}

.ais-SearchBox-form {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

}


/*search button */
.ais-SearchBox-submit {

}

.ais-SearchBox-submitIcon {
  width: 20px;
  height: 20px;
  fill: #82828C;
}


.ais-InfiniteHits-list{
  display: flex;
  row-gap:  48px;
  column-gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 48px;
}



.ais-InfiniteHits-loadMore {
  align-self: flex-end;
}

.ais-InfiniteHits-item {
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  width: 420px;
  height: 504px;
}

/*@media(max-width: 1512px) {*/
/*  .ais-InfiniteHits-item{*/
/*    width: 660px;*/
/*  }*/
/*}*/


/*stats*/
.ais-Stats-text {
  font-size: 16px;
  color: #5C5C63;
  line-height: 24px;
}


/*select*/
.ais-SortBy-select {
  font-size: 16px;
  line-height: 24px;
  color: #5C5C63;
  .ais-SortBy-option {
    background-color: red !important;
  }
}


/*checkbox*/

.ais-RefinementList-label{
  display: flex;
  /*flex-direction: row-reverse;*/
  /*justify-content: space-between;*/
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #37373B
}

.ais-RefinementList-labelText {
  font-size: 14px;
  line-height: 20px;
  color: #37373B
}

.filterCollapse {
  .ant-collapse-header {
    padding:  16px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }
}


/*load more button*/

.ais-InfiniteHits-loadMore {
  /*color: #F42847;*/
  font-size: 16px;
  cursor: auto;
  color: #5C5C63;
  /*font-weight: 600;*/
  /*line-height: 24px;*/
  /*border: 1.5px solid #F42847;*/
  /*padding: 8px 16px;*/
  /*border-radius: 99999px;*/
  display: flex;
  margin: 0 auto;
}

.affected-modal {
  width: 100% !important;
}

.affected-modal .ant-modal-header {
  border: none;
  padding-top: 32px;
  padding-bottom: 0;
}

.affected-modal .ant-modal-content {
  border-radius: 0;
}

.affected-modal .ant-modal-close-x {
  font-size: 20px;
  color: #37373B;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  height: fit-content;
  width: fit-content;
}


.affected-modal .ant-modal-close{
  top: 40px;
  right: 40px;
}

.FilterPanelCollapse {
  .ant-collapse-header {
    font-size: 16px;
    font-weight: 600;
    color: #37373B;
  }
}

.studentCard .ant-tag {
  margin: 0;
}
.ais-InfiniteHits-loadMore--disabled {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background-color: #f7f7f7;
  cursor: not-allowed;
}



/*Instant Search pagination*/


/*.ais-Pagination {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/
.ais-Pagination-list {
  display: flex;
  padding-top: 32px;
  justify-content: center;
  align-items: center;
}
.ais-Pagination-item {
  border: 1px solid #d9d9d9;
  color: #202533;
  padding: 0 6px;
  margin: 0 2px;
  border-radius: 4px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
}

.ais-Pagination-item--page{
  color: #202533;
}

.ais-Pagination-item--previousPage {
  font-size:30px;
}


.ais-Pagination-item--firstPage {
  font-size:30px;

}


.ais-Pagination-item--nextPage {
  font-size:30px;

}

.ais-Pagination-item--disabled {
  border: 1px solid #d9d9d9;
  color: #d9d9d9;

}

.ais-Pagination-item--selected {
  border: 1px solid #f21850;
  color: #f21850;
}

.ais-RefinementList-showMore {
  color: #f21850;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.ais-RefinementList-checkbox input[type=checkbox]{
  background-color: #f21850;
}

 /*antd Tabs*/

.ant-tabs-tab {
  color: #5C5C63 !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #F42847 !important;
}

/*antd Tabs for studies in students profiles*/

.studies-tabs .ant-tabs-tab {
  font-weight: 600;
}

.studies-tabs .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 600;
}


/*
Talentpool media
*/

@media (min-width: 2329px) {
  .row-content {
    max-width: 2000px;
    margin: 0 auto;
  }
  .ais-InfiniteHits-item {
    max-width: 396px;
  }
}

@media (min-width: 1750px) and (max-width: 2328px) {
  .ais-InfiniteHits-item {
    width: 31.8%;
  }
}

@media (min-width: 1441px) and (max-width: 1749px) {
  .ais-InfiniteHits-item {
    width: 48.5%;
  }
}

@media (min-width: 1101px) and (max-width: 1540px) {
  .ais-InfiniteHits-item {
    width: 47.5%;
  }
}

@media (max-width: 1900px) {
  .ais-InfiniteHits-list {
    justify-content: flex-start;
  }
  .ais-InfiniteHits-item {
    min-width: 48.3%;
  }
}

@media (max-width: 1045px) {
  .ais-InfiniteHits-item {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .ais-InfiniteHits-list {
    justify-content: space-between;
  }
}

