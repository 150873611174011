#ContactOverlay {
  z-index: 200;
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 225px;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
}

#ContactOverlay.expanded-collapse {
  width: 350px;
}

#ContactOverlay .ant-collapse-content-box {
  padding: 0px;
}

#ContactOverlay .cross-fade-container {
  position: relative;
  height: 40px;
}

#ContactOverlay .cross-fade-top,
.cross-fade-bottom {
  position: absolute;

  left: 0;

  transition: opacity 0.3s ease-in-out;
}

#ContactOverlay .cross-fade-top {
  opacity: 1;
}

#ContactOverlay .cross-fade-bottom {
  opacity: 0;
}
