#AudioDropzone.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #cccdd4;
  cursor: pointer;
  text-align: center;
}

#AudioDropzone.circle {
  border-radius: 100%;
}

#AudioDropzone .upload-icon {
  background-color: #ffffff;
  font-size: 28px;
  margin-top: 29px;
  border-radius: 100%;
  height: 42px;
  width: 42px;
  line-height: 48px;
  color: rgb(119, 119, 129);
}
