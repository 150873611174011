#PostList {
  margin: auto;
  max-width: 750px;
  min-width: 250px;
  background-color: #ffffff;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
  margin-bottom: 30px;
}

#PostList .message-list {
  padding: 20px 20px;
  text-align: center;
}

.postListEnd {
  margin-bottom: 50px;
  text-align: center;
}
