.advert-targeting-link {
  cursor: pointer;
}

.advert-targeting-link:hover {
  color: #f21850;
}

.advert-list-item:hover {
  background-color: #EFF0F2;
}