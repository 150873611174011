#AdvertSelectionModal .post-card {
  border: 1px solid #eff0f2;
  margin: 10px;
  cursor: pointer;
}

#AdvertSelectionModal .post-card:hover {
  box-shadow: 0px 0px 1px 1px #ffe6e8;
  background-color: #ffe6e8;
  border: 1px solid #ffe6e8;
}

#AdvertSelectionModal .post-card-header {
  /* border-bottom: 1px solid #EFF0F2;
  background-color: #fafafa; */
  padding: 10px 0px;
  font-weight: 500;
  font-weight: 500;
  color: #4A4A4A;
}
