.PostCreator {
  margin: auto;
  max-width: 750px;
  min-width: 250px;
  background-color: #ffffff;
  padding: 20px;
  text-align: right;
  border-radius: 5px;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
}

.PostCreator .form {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 15px;
  border-top-left-radius: 0;
}

.PostCreator .tabs {
  text-align: left;
}
.PostCreator .tabs .ant-tabs-bar {
  margin-bottom: 0;
  border-bottom-width: 0;
}

.PostCreator .ant-tabs {
  overflow: visible;
}
.PostCreator .tab .validation-hint {
  background-color: 'green';
}

.PostCreator .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border-color: #d8d8d8;
  margin-right: 5px;
  width: 100px;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: 500;
  background-color: #edeff4;
  color: #82828c;
}

.PostCreator .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border-bottom-color: transparent;
  background-color: #ffffff;
  color: #f21850;
}

.PostCreator .form .text-area.ant-input {
  border-width: 0px;
  margin: 1px;
  border-radius: 8px;
  box-shadow: none;
  border-color: transparent;
  border-right-width: 0px !important;
  margin-bottom: 5px;
}

.PostCreator .form .text-area.ant-input:hover {
  border-width: 0px;
  box-shadow: none;
  border-color: transparent;
  border-right-width: 0px !important;
  margin-bottom: 5px;
}

.PostCreator .form .text-area.ant-input:focus {
  border-width: 0px;
  box-shadow: none;
  border-color: transparent;
  border-right-width: 0px !important;
  margin-bottom: 5px;
}

.PostCreator .form .has-error .text-area.ant-input {
  border-width: 1px !important;
  border-color: #f5222d !important;
  margin: 0px;
  margin-bottom: 5px;
}

.PostCreator .form .has-error .text-area.ant-input:hover {
  border-width: 1px !important;
  border-color: #f5222d !important;
  margin: 0px;
  margin-bottom: 5px;
}

.PostCreator .form .has-error .text-area.ant-input:focus {
  border-width: 1px !important;
  border-color: #f5222d !important;
  margin: 0px;
  margin-bottom: 5px;
}

.PostCreator .form .ant-calendar-picker,
.PostCreator .form .ant-time-picker {
  width: 100%;
}

.PostCreator .ant-form-item {
  margin-bottom: 0;
}

.PostCreator .ant-form-explain {
  display: none;
}

.PostCreator .ant-btn-primary {
  padding: 0 25px;
  border-width: 0;
  border-radius: 100px;
}

.PostCreator .fontButtons {
  display: inline-block;
  float: left;
  text-align: left;
}

.PostCreator .attachmentButtons {
  display: inline-block;
  float: right;
}

.PostCreator .attachment-select {
  display: inline-block;
  padding: 0 5px;
}

.PostCreator .attachment-select button {
  border-width: 0px;
  font-size: 20px;
  color: #82828c;
}

.PostCreator .remove-option button {
  border-width: 0px;
  background-color: transparent;
  color: #c2c2cd;
}

.PostCreator .attachment-select .active {
  background-color: #edeff4;
  color: #f21850;
}

.PostCreator .attachment-footer {
  background-color: #e4e6eb;
  border-radius: 0 0 7px 7px;
  border-top: 1px solid #c2c2cd;
}

.PostCreator #AttachmentContainer {
  background-color: #edeff4;
  border-radius: 7px;
}

.PostCreator .gallery-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  background-color: #ffffff;
}

.PostCreator .gallery-overlay {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
}

.PostCreator .video-overlay {
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0;
}

.PostCreator .inner-overlay-two {
  width: 100%;
  height: 100%;
  position: relative;
}

.PostCreator .inner-overlay-three {
  width: 42px;
  height: 42px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 100%;
  background-color: #00000066;
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
  line-height: 42px;
}

.PostCreator .event-input {
  width: 100%;
  padding: 5px 0px;
}

.PostCreator .padded-input {
  width: 100%;
  padding: 5px 0px;
}

.PostCreator #TriggerPicker .ant-calendar-picker-container,
.PostCreator #TriggerPicker .ant-time-picker-panel {
  top: 0 !important;
  left: 0 !important;
}

.PostCreator #TriggerPicker .ant-time-picker-panel {
  width: 112px;
}

.PostCreatorVerifyModal {
  text-align: left;
}

.PostCreatorVerifyModal .ant-modal-body {
  padding: 0px 30px;
}

.PostCreatorVerifyModal .ant-form-item {
  margin-bottom: 0px;
}
.PostCreatorVerifyModal .verify-section:first-child {
  margin-top: 44px;
  padding-bottom: 0px;
}
.PostCreatorVerifyModal .verify-section {
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0px;
}

.PostCreatorVerifyModal .verify-section:last-child {
  border-bottom: 0px;
}

.PostCreatorVerifyModal .verify-section .verify-section-headline {
  text-transform: uppercase;
  font-size: 14px;
}

.PostCreatorVerifyModal .verify-section .channels {
}

.PostCreatorVerifyModal .verify-section .channels .channel {
  border-bottom: 1px solid #d8d8d8;
  padding: 13px 0px;
  font-size: 16px;
}

.PostCreatorVerifyModal .verify-section .channels .channel:last-child {
  border-bottom: 0px;
}

.PostCreatorVerifyModal .verify-section .channels .channel .check {
  cursor: pointer;
  padding: 4px 17px;
  transition-duration: 250ms;
  font-weight: 700;
  border-radius: 100px;
  color: #d8d8d8;
  border: 1px solid #d8d8d8;
}

.PostCreatorVerifyModal .verify-section .channels .channel .check.active {
  color: #f21850;
  border: 1px solid #f21850;
}

.PostCreatorVerifyModal .verify-section .channels .channel .push {
  cursor: pointer;
  transition-duration: 250ms;
  font-size: 20px;
  color: #d8d8d8;
}

.PostCreatorVerifyModal .verify-section .channels .channel .push.hidden {
  color: #fff;
  cursor: default;
}

.PostCreatorVerifyModal .verify-section .channels .channel .push.active {
  color: #f21850;
}

.PostCreatorVerifyModal .verify-section .publish-button {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 0px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  transition-duration: 250ms;
}

.PostCreatorVerifyModal .verify-section .publish-button.active {
  background: #ffffff;
  border: 1px solid #f25c78;
  box-shadow: 0 2px 4px 0 rgba(147, 147, 147, 0.5);
  border-radius: 8px;
}

.PostCreatorVerifyModal .verify-section .publish-button-costum > div > input {
  height: 38px;
  border-radius: 8px;
  border: none;
  width: 100%;
  margin-bottom: 2px;
}

.PostCreatorVerifyModal .verify-section .publish-button-costum {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  width: 100% !important;
}

.PostCreatorVerifyModal .verify-section .publish-button-costum.active {
  border: 1px solid #f25c78;
  box-shadow: 0 2px 4px 0 rgba(147, 147, 147, 0.5);
  border-radius: 8px;
}

.PostCreatorVerifyModal
  .verify-section
  .publish-button-costum.active
  > div
  > i {
  color: #f25c78;
}

.PostCreatorVerifyModal .align-left {
  text-align: left;
}

.PostCreatorVerifyModal .active {
  color: #f21850;
}

.PostCreatorVerifyModal .ant-btn-primary {
  font-weight: 700;
  padding: 0px 30px;
  border-width: 0;
  border-radius: 100px;
}
