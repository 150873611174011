#GalleryModal .slide {
  text-align: center;
  overflow: hidden;
}

#GalleryModal .slide img {
  object-fit: contain;
  height: 350px;
  width: 100%;
  margin: auto;
}

#GalleryModal .slide-arrow {
  cursor: pointer;
  color: #f21850;
}

#GalleryModal .slick-dots li button {
  background: #f21850;
}