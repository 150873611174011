.table-highlight-row {
  border-bottom: none;
}

.table-highlight-row td {
  background-color: #fafafa;
  font-weight: 600;
  border-bottom: 0px !important;
  border-top: 2px solid #e8e8e8;
}

#TableFilter {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

#TableFilter input {
  width: 250px;
  margin-right: 8px;
}

#TableFilter button {
  margin-right: 8px;
}

#CompanyTotal {
  padding-bottom: 20px;
  text-align: left;
}

#CompanyTotal .card {
  max-width: 250px;
  min-width: 200px;
  text-align: center;
  margin: 15px 20px 15px 0px;
}

#CompanyTotal .title {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}

#CompanyTotal .number {
  color: #f21850;
  font-size: 25px;
}

#CompanyTotal .sub-number {
  color: #82828c;
  font-size: 18px;
}

.chartContainer {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
}

.chartInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  line-height: 170%;
  /*overflow: hidden;*/
}

.chartTitle {
  font-size: 24px;
  padding-bottom: 8px;
  color: #767676;
  text-align: center;
}

.chartRankingLabel {
  white-space: nowrap;
  font-size: 32px;
  font-weight: 500;
  text-align: center;

}
