.clickable-primary {
    color: #f21850;
    cursor: pointer;
}
.clickable-primary:hover {
    color: #f21850;
}

.ant-tabs-tab {
    color: #C6C6CA;
    padding: 12px 24px;
}


.ais-ClearRefinements {
    margin: 1em 0;
}

.ais-SearchBox {
    margin: 1em 0;
}

.ais-Pagination {
    margin-top: 1em;
}

.left-panel {
    float: left;
    width: 200px;
}

.right-panel {
    margin-left: 210px;
}

.ais-InstantSearch {
    max-width: 960px;
    overflow: hidden;
    margin: 0 auto;
}

.ais-Hits-item {
    margin-bottom: 1em;
    width: calc(50% - 1rem);
}

.ais-Hits-item img {
    margin-right: 1em;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5em;
}

.hit-name {
    margin-bottom: 0.5em;
}

.hit-description {
    color: grey;
    margin-bottom: 0.5em;
}

.hit-info {
    font-size: 90%;
}

/* Clear refinements container */

.clear-filters {
    align-items: center;
    display: flex;
}

.clear-filters svg {
    margin-right: 8px;
}

/* Panel */

.container-body .ais-Panel {
    border-top: 1px solid #ebecf3;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.ais-Panel-header {
    font-family: Hind, sans-serif;
}

/* Search box */

.header .ais-SearchBox {
    height: 64px;
    width: 740px;
}

.header .ais-SearchBox .ais-SearchBox-input {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.2);
    font-family: Hind, sans-serif;
    height: 64px;
    /*
      The "Hind" font family is vertically off-balance.
      Adding 4px of padding top makes it more vertically aligned.
    */
    padding: 4px 48px 0 64px;
}

.header .ais-SearchBox-submit {
    padding: 0 1rem 0 2rem;
    width: 64px;
}

.header .ais-SearchBox .ais-SearchBox-input::placeholder {
    color: rgba(33, 36, 61, 0.24);
    opacity: 1; /* Firefox */
}

.ais-SearchBox-input:-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-input::-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-submit {
    color: #e2a400;
}

.ais-RefinementList .ais-SearchBox-input {
    font-family: Hind, sans-serif;
    /*
      The "Hind" font family is vertically off-balance.
      Adding some padding top makes it more vertically aligned.
    */
    padding-top: 2px;
}

/* Hits */

.hit {
    color: #21243d;
    font-size: 14px;
    line-height: 18px;
}

.hit h1 {
    font-size: 14px;
}

.hit-category {
    color: #21243d;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 8px;
    opacity: 0.7;
    text-transform: uppercase;
}

.hit-description {
    margin-top: 2px;
}

.hit-info-container {
    overflow-wrap: break-word;
    word-break: break-word;
}

.hit-image-container {
    align-items: center;
    display: flex;
    height: 174px;
    justify-content: center;
    margin: auto;
    width: 174px;
}

.hit-image {
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

.hit-em {
    color: #e2a400;
    font-size: 11px;
    font-weight: 600;
}

.hit-rating {
    border: 1px solid rgba(226, 164, 0, 0.5);
    border-radius: 4px;
    margin-left: 4px;
    padding: 0 4px;
}

.hits-empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 300px;
}

.hits-empty-state-title {
    font-family: Hind;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
}

.hits-empty-state-description {
    color: rgba(35, 37, 51, 0.6);
    font-size: 0.875rem;
    text-align: center;
}

.hits-empty-state .ais-ClearRefinements {
    margin-top: 1rem;
}

.hits-empty-state .ais-ClearRefinements-button--disabled {
    display: none;
}

.hits-empty-state .ais-ClearRefinements-button {
    background: rgba(10, 8, 41, 0.04);
    border-radius: 3px;
    color: #21243d;
    min-height: 48px;
    padding: 16px 24px;
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.ais-ToggleRefinement-checkbox {
    font: inherit;
    margin-left: 1rem;
    margin-right: 0;
    position: relative;
}

.ais-ToggleRefinement-checkbox:checked::before {
    color: #e2a400;
}

.ais-ToggleRefinement-checkbox::before {
    align-items: center;
    color: rgba(33, 36, 61, 0.32);
    content: 'No';
    display: flex;
    font-size: 0.8rem;
    height: 16px;
    position: absolute;
    right: 38px;
}

.ais-ToggleRefinement-checkbox:checked::before {
    content: 'Yes';
}

.ais-ToggleRefinement-count {
    display: none;
}

/* RatingMenu */

.ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
    opacity: 0.5;
}

.ais-RatingMenu-starIcon {
    margin-right: 0.5rem;
}

/* Hide all mobile-specific design on desktop */

@media (min-width: 900px) {
    [data-layout='mobile'] {
        display: none;
    }
}
