.feed-card {
  margin: auto;
  max-width: 750px;
  min-width: 250px;
  background-color: #ffffff;
  padding: 20px;
  /*text-align: right;*/
  border-radius: 5px;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
}

.uninow-post {
  padding: 20px 20px;
  border-bottom: 1px solid rgb(230, 236, 240);
}

.uninow-post div.gallery {
  overflow: hidden;
  cursor: pointer;
}

.uninow-post .gallery img {
  width: 100%;
  max-height: 550px;
  object-fit: contain;
  border: 2px solid white;
  background-position: center;
}

.uninow-post .company-promotion {
  background-color: #eef0f2;
}

.uninow-post .blog {
  background-color: #eef0f2;
  cursor: pointer;
  margin-bottom: 1rem;
}

.uninow-post .blog a {
  color: inherit;
}

.uninow-post .blog img {
  display: block;

  max-width: 1000px;
  max-height: 1414px;

  width: 100%;
  height: auto;

  object-fit: cover;
  background-position: center;
}

.uninow-post .podcast {
  background-color: #eef0f2;
  margin-bottom: 1rem;
}

.uninow-post .podcast a {
  color: inherit;
}

.uninow-post .podcast img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  background-position: center;
}

.uninow-post .event {
  background-color: #eef0f2;
}

.uninow-post .gallery .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-size: 3rem;
  border: 2px solid white;
}

.uninow-post .gallery .overlay-inner-relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.uninow-post .gallery .overlay-inner-absolute {
  width: 100%;
  height: 50%;
  text-align: center;
  position: absolute;
  top: 25%;
}

.uninow-post:last-child {
  border-bottom: none;
}

.uninow-post .avatar {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: inline-block;
}

.uninow-post .statistic-button {
  float: right;
  padding: 10px 20px;
}

.uninow-post .statistic-button:hover {
  background-color: #f8f8f8;
}

.uninow-post .ant-btn-primary {
  border-radius: 100px;
}

.PostChangePublishingDateModal {
  text-align: left;
}

.PostChangePublishingDateModal .ant-modal-body {
  padding: 0px 30px;
}

.PostChangePublishingDateModal .ant-form-item {
  margin-bottom: 0px;
}
.PostChangePublishingDateModal .verify-section:first-child {
  margin-top: 44px;
  padding-bottom: 0px;
}
.PostChangePublishingDateModal .verify-section {
  padding: 20px 0px;
}

.PostChangePublishingDateModal .verify-section:last-child {
  border-bottom: 0px;
}

.PostChangePublishingDateModal .verify-section .verify-section-headline {
  text-transform: uppercase;
  font-size: 14px;
}

.PostChangePublishingDateModal .verify-section .publish-button {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 0px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  transition-duration: 250ms;
}

.PostChangePublishingDateModal .verify-section .publish-button.active {
  background: #ffffff;
  border: 1px solid #f25c78;
  box-shadow: 0 2px 4px 0 rgba(147, 147, 147, 0.5);
  border-radius: 8px;
}

.PostChangePublishingDateModal .verify-section .publish-button-costum > div > input {
  height: 38px;
  border-radius: 8px;
  border: none;
  width: 100%;
  margin-bottom: 2px;
}

.PostChangePublishingDateModal .verify-section .publish-button-costum {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  width: 100% !important;
}

.PostChangePublishingDateModal .verify-section .publish-button-costum.active {
  border: 1px solid #f25c78;
  box-shadow: 0 2px 4px 0 rgba(147, 147, 147, 0.5);
  border-radius: 8px;
}

.PostChangePublishingDateModal
  .verify-section
  .publish-button-costum.active
  > div
  > i {
  color: #f25c78;
}

.PostChangePublishingDateModal .align-left {
  text-align: left;
}

.PostChangePublishingDateModal .active {
  color: #f21850;
}

.PostChangePublishingDateModal .ant-btn-primary {
  font-weight: 700;
  padding: 0px 30px;
  border-width: 0;
  border-radius: 100px;
}
