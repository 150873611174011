.preview .basicFont {
  word-wrap: break-word;
  font-size: 1em;
  color: #4a4a4a;
}

.preview .divider {
  height: 5px;
  background-color: #edeff4;
}

.preview .basicFontLight {
  word-wrap: break-word;
  font-size: 1em;
  color: #4a4a4a;
  font-weight: 100;
}
.preview .sectionContainer {
  margin: 5%;
}

.preview .sectionHeadline {
  word-wrap: break-word;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 2%;
}

.preview .sectionIcon {
  font-size: 1em;
  color: #f21850;
}

.preview .basicLink {
  word-wrap: break-word;
  font-size: 1em;
  color: #f21850;
}

.preview .headline {
  font-size: 1.7em;
  font-weight: 600;
}

.preview .subtitle {
   font-size: 1.0em;
   font-weight: 600;
 }

.preview .logo-overview {
  width: 30%;
  height: auto;
  position: absolute;
  border: 1px solid #edeff4;
  left: 0px;
  transform: translate(0%, -70%);
}

.preview .follow-button {
  border: 1px solid #8e8e8e;
  padding: 0.4em 1.5em;
  border-radius: 20px;
  width: auto;
  display: inline-block;
  font-size: 0.9em;
}

.preview .title {
  font-size: 1.25em;
  font-weight: 600;
}

.preview .avatar {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.preview .benefitsIcon {
  font-size: 1.5em;
  color: #8e8e8e;
}

.preview .subline {
  font-size: 1em;
  white-space: nowrap;
  text-overflow: hidden;
  padding-top: 10%;
}

.preview .plusLink {
  font-size: 1.5em;
  color: #f21850;
  cursor: pointer;
}

.preview .navbarBorder {
  position: absolute;
  background-color: #8e8e8e;
  height: 1px;
  width: 100%;
}

.preview .navbar {
  position: absolute;
  height: 30px;
  z-index: 40;
  width: 100%;
  background-color: #f21850;
  background-size: cover;
  vertical-align: center;
}

.preview .navbar.centerText {
  padding:3px
}

.preview .navbarSpacer {
  height: 30px;
}

.preview .navbarBackButton {
  font-size: 100;
  color: "#f21850";
}

.preview .rowContainer {
  padding: 3%;
  max-width: 100%;
  overflow-wrap: break-word;
  background-color:#fff;
  margin-bottom: 3%;
  text-overflow: ellipsis;
  margin-top: 3%;
}


.preview .textContainer {
  margin-top: 5%;
}

.preview a {
  color: #f21850;
}

.box-shadow{
  -webkit-box-shadow: 0px 0px 23px 5px #ddd;
  box-shadow: 0px 0px 23px 5px #ddd;
}
.gradient-to-black {
  background: #eee;
  background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(149,149,149,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(149,149,149,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(149,149,149,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#959595",GradientType=1);
}
