#PostGrid .post-grid-item {
  height: 100%;
  width: 100%;
  position: absolute;
}

#PostGrid .post-grid-item:hover {
  background-color: #EFF0F2;
  opacity: 0.5;
}

#PostGrid .post-grid-load-more-button:hover {
  background-color: #EFF0F2;
  opacity: 0.5;
}