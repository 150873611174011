@font-face {
    font-family: 'unicons';
    src: url('fonts/uninow_appicons.eot?30753751');
    src: url('fonts/uninow_appicons.eot?30753751#iefix') format('embedded-opentype'),
    url('fonts/uninow_appicons.woff2?30753751') format('woff2'),
    url('fonts/uninow_appicons.woff?30753751') format('woff'),
    url('fonts/uninow_appicons.ttf?30753751') format('truetype'),
    url('fonts/uninow_appicons.svg?30753751#uninow_appicons') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'uninow_appicons';
    src: url('../font/uninow_appicons.svg?30753751#uninow_appicons') format('svg');
  }
}
*/
[class^="un-"]:before, [class*=" un-"]:before {
    font-family: "unicons";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.un-arrow-back-small:before { content: '\e800'; } /* '' */
.un-list:before { content: '\e801'; } /* '' */
.un-tour:before { content: '\e802'; } /* '' */
.un-searchsavefull:before { content: '\e803'; } /* '' */
.un-searchsave:before { content: '\e804'; } /* '' */
.un-radio:before { content: '\e805'; } /* '' */
.un-link:before { content: '\e806'; } /* '' */
.un-hall:before { content: '\e807'; } /* '' */
.un-instagram:before { content: '\e808'; } /* '' */
.un-kununu-box:before { content: '\e809'; } /* '' */
.un-kununu:before { content: '\e80a'; } /* '' */
.un-linkedin:before { content: '\e80b'; } /* '' */
.un-pinterest-box:before { content: '\e80c'; } /* '' */
.un-pinterest:before { content: '\e80d'; } /* '' */
.un-snapchat:before { content: '\e80e'; } /* '' */
.un-twitter-box:before { content: '\e80f'; } /* '' */
.un-twitter-circle:before { content: '\e810'; } /* '' */
.un-twitter:before { content: '\e811'; } /* '' */
.un-vimeo:before { content: '\e812'; } /* '' */
.un-whatsapp:before { content: '\e813'; } /* '' */
.un-xing-box:before { content: '\e814'; } /* '' */
.un-xing-circle:before { content: '\e815'; } /* '' */
.un-xing:before { content: '\e816'; } /* '' */
.un-youtube:before { content: '\e817'; } /* '' */
.un-briefcase:before { content: '\e818'; } /* '' */
.un-school:before { content: '\e819'; } /* '' */
.un-game:before { content: '\e81a'; } /* '' */
.un-google-plus:before { content: '\e81b'; } /* '' */
.un-google-plus-box:before { content: '\e81c'; } /* '' */
.un-github-circle:before { content: '\e81d'; } /* '' */
.un-facebook:before { content: '\e81e'; } /* '' */
.un-facebook-box:before { content: '\e81f'; } /* '' */
.un-blogger:before { content: '\e820'; } /* '' */
.un-blogger-outline:before { content: '\e821'; } /* '' */
.un-wheelchair-accessibility:before { content: '\e822'; } /* '' */
.un-trending-up:before { content: '\e823'; } /* '' */
.un-tram:before { content: '\e824'; } /* '' */
.un-timer:before { content: '\e825'; } /* '' */
.un-Team-Events:before { content: '\e826'; } /* '' */
.un-swim:before { content: '\e827'; } /* '' */
.un-sofa:before { content: '\e828'; } /* '' */
.un-silverware:before { content: '\e829'; } /* '' */
.un-seat-legroom-normal:before { content: '\e82a'; } /* '' */
.un-arrow-forward-small:before { content: '\e82b'; } /* '' */
.un-bag-outline:before { content: '\e82c'; } /* '' */
.un-book-outline:before { content: '\e82d'; } /* '' */
.un-bookmarks-outline:before { content: '\e82e'; } /* '' */
.un-bookmarks:before { content: '\e82f'; } /* '' */
.un-briefcase-outline-thin:before { content: '\e830'; } /* '' */
.un-tag:before { content: '\e831'; } /* '' */
.un-account-switch:before { content: '\e832'; } /* '' */
.un-food:before { content: '\e833'; } /* '' */
.un-gamepad-variant:before { content: '\e834'; } /* '' */
.un-gas-station:before { content: '\e835'; } /* '' */
.un-globe:before { content: '\e836'; } /* '' */
.un-google-maps:before { content: '\e837'; } /* '' */
.un-heart-pulse:before { content: '\e838'; } /* '' */
.un-highway:before { content: '\e839'; } /* '' */
.un-home-modern:before { content: '\e83a'; } /* '' */
.un-home:before { content: '\e83b'; } /* '' */
.un-local-play:before { content: '\e83c'; } /* '' */
.un-nature-people:before { content: '\e83d'; } /* '' */
.un-parking:before { content: '\e83e'; } /* '' */
.un-linkedin-box:before { content: '\e83f'; } /* '' */
.un-seat-flat:before { content: '\e840'; } /* '' */
.un-presentation-play:before { content: '\e841'; } /* '' */
.un-paw:before { content: '\e842'; } /* '' */
.un-certificate:before { content: '\e843'; } /* '' */
.un-clock-fast:before { content: '\e844'; } /* '' */
.un-credit-card-plus:before { content: '\e845'; } /* '' */
.un-database-plus:before { content: '\e846'; } /* '' */
.un-domain:before { content: '\e847'; } /* '' */
.un-drinks:before { content: '\e848'; } /* '' */
.un-duck:before { content: '\e849'; } /* '' */
.un-food-apple:before { content: '\e84a'; } /* '' */
.un-zoom-outline-thin:before { content: '\e84b'; } /* '' */
.un-zoom-outline:before { content: '\e84c'; } /* '' */
.un-airballoon:before { content: '\e84d'; } /* '' */
.un-airplane:before { content: '\e84e'; } /* '' */
.un-attach-money:before { content: '\e84f'; } /* '' */
.un-baby-buggy:before { content: '\e850'; } /* '' */
.un-beach:before { content: '\e851'; } /* '' */
.un-bow-tie:before { content: '\e852'; } /* '' */
.un-calendar-clock:before { content: '\e853'; } /* '' */
.un-car:before { content: '\e854'; } /* '' */
.un-cash-multiple:before { content: '\e855'; } /* '' */
.un-tag-outline:before { content: '\e856'; } /* '' */
.un-tag-outline-thin:before { content: '\e857'; } /* '' */
.un-settings:before { content: '\e858'; } /* '' */
.un-settings-thin:before { content: '\e859'; } /* '' */
.un-school-outline:before { content: '\e85a'; } /* '' */
.un-school-outline-thin:before { content: '\e85b'; } /* '' */
.un-pin:before { content: '\e85c'; } /* '' */
.un-timelapse:before { content: '\e85d'; } /* '' */
.un-briefcase-outline:before { content: '\e85e'; } /* '' */
.un-calender-outline:before { content: '\e85f'; } /* '' */
.un-chat-outline-thin:before { content: '\e860'; } /* '' */
.un-chat-outline:before { content: '\e861'; } /* '' */
.un-feed-outline-thin:before { content: '\e862'; } /* '' */
.un-feed-outline:before { content: '\e863'; } /* '' */
.un-feed:before { content: '\e864'; } /* '' */
.un-food-outline:before { content: '\e865'; } /* '' */
.un-heart-outline-thin:before { content: '\e866'; } /* '' */
.un-heart-outline:before { content: '\e867'; } /* '' */
.un-heart:before { content: '\e868'; } /* '' */
.un-mail-outline:before { content: '\e869'; } /* '' */
.un-pin-outline:before { content: '\e86a'; } /* '' */
.un-scooter:before { content: '\e86b'; } /* '' */
.un-tiktok:before { content: '\e86c'; } /* '' */
.un-studyid-outline:before { content: '\e86d'; } /* '' */
.un-premium:before { content: '\e86e'; } /* '' */
.un-scan-qr_01:before { content: '\e86f'; } /* '' */
.un-authenticator-outline:before { content: '\e870'; } /* '' */
.un-scan-qr_03:before { content: '\e871'; } /* '' */
.un-scan-qr_02:before { content: '\e872'; } /* '' */
.un-premium-outline:before { content: '\e873'; } /* '' */
.un-check-outline:before { content: '\e874'; } /* '' */
.un-icon_shortcut:before { content: '\e875'; } /* '' */
.un-glassdoor:before { content: '\e876'; } /* '' */
.un-indeed:before { content: '\e877'; } /* '' */
.un-dice-swap:before { content: '\e879'; } /* '' */
.un-dice-swap-thin:before { content: '\e87a'; } /* '' */
.un-watchado:before { content: '\e87b'; } /* '' */
.un-watchado-outline:before { content: '\e87c'; } /* '' */
.un-spotify:before { content: '\e87d'; } /* '' */
.un-spotify-outline:before { content: '\e87e'; } /* '' */
.un-abroad-outline:before { content: '\e880'; } /* '' */
.un-abroad:before { content: '\e883'; } /* '' */
.un-twitch:before { content: '\f1e8'; } /* '' */
