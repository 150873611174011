#ThreeColumnLayout {
  flex-grow: 1;
  padding: 20px 60px;
}

@media (max-width: 768px) {
  #ThreeColumnLayout {
    padding: 20px 15px;
  }
}
