#Preview {
  background-size: cover;
}

#Preview .avatar {
  width: 40px;
  height: 40px;
  border-radius: 15%;
  display: inline-block;
}

#Preview .phone {
  border-radius: 35px;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
}

#Preview .blog {
  background-color: #eef0f2;
  cursor: pointer;
  margin-bottom: 1rem;
}

#Preview .blog a {
  color: inherit;
}

#Preview .blog img {
  display: block;

  max-width: 1000px;
  max-height: 1414px;

  width: 100%;
  height: auto;

  object-fit: cover;
  background-position: center;
}

#Preview .podcast {
  background-color: #eef0f2;
  margin-bottom: 1rem;
}

#Preview .podcast a {
  color: inherit;
}

#Preview .podcast img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

#Preview .event {
  background-color: #eef0f2;
}

#Preview .poll {
  background: rgb(230, 236, 240);
  border-radius: 100px;
  padding: 3px;
  padding-left: 12px;
  margin-top: 8px;
}

#Preview div.gallery {
  margin-top: 5px;
  overflow: hidden;
}

#Preview .gallery img {
  width: 100%;
  max-height: 450px;
  object-fit: contain;
  border: 2px solid white;
}

#Preview .gallery .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-size: 3rem;
  border: 2px solid white;
}

#Preview .gallery .overlay-inner-relative {
  width: 100%;
  height: 100%;
  position: relative;
}

#Preview .gallery .overlay-inner-absolute {
  width: 100%;
  height: 50%;
  text-align: center;
  position: absolute;
  top: 25%;
}
