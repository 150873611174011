#ProfileOverview {
  position: relative;
  top: 0;
  background-color: #ffffff;
  margin-bottom: 25px;
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 0 10px 10px 10px;
  z-index: 2;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
}

#ProfileOverview .action-list {
  position: absolute;
  height: 100%;
  text-align: center;
  line-height: 40px;
  padding-left: 10px;
  top: 0;
  right: 0px;
  transition: right 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: -1;
}

#ProfileOverview .slide {
  right: -50px;
}

#ProfileOverview .action-button {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 0 2px 22px 0 rgba(196, 196, 196, 0.5);
}

#ProfileOverview .action-icon {
  font-size: 20px;
}

#ProfileOverview .header-container {
  position: relative;
  width: 100%;
  padding-top: 50%;
}

#ProfileOverview img.header {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 10px 0 0;
  top: 0px;
  left: 0px;
}

#ProfileOverview .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-align: center;
}

#ProfileOverview .logo-container {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  margin-left: 10%;
  margin-top: -45px;
  text-align: center;
}

#ProfileOverview .logo-subcontainer {
  margin: auto;
  position: relative;
  width: 90px;
  box-sizing: content-box;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100%;
}

#ProfileOverview img.logo {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 100%;
}

#ProfileOverview .card-content {
  padding: 20px;
}

#ProfileOverview .round {
  border-radius: 100%;
}

#ProfileOverview .edit-icon {
  font-size: 20px;
  cursor: pointer;
}

#ProfileOverview .divider {
  height: 1px;
  background-color: #d8d8d8;
  margin: 20px 0px;
}

#ProfileOverview .statistic-button {
  cursor: pointer;
}

#ProfileOverview .statistic-button:hover {
  background-color: #f8f8f8;
}

@media (max-width: 768px) {
  #ProfileOverview {
    width: 100%;
    min-width: 250px;
    max-width: 768px;
  }

  #ProfileOverview .action-list {
    top: 10px;
    right: 10px;
    transition: right 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 1;
  }

  #ProfileOverview .slide {
    right: 10px;
  }
}

a {
  color: #f21850;
}
a:hover {
  color: #f21850;
}
