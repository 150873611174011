#AdvertContingent {
  padding-bottom: 20px;
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
#AdvertContingent .ant-card-head {
  min-height: 40px;
}

#AdvertContingent .ant-card-head-title,
#AdvertContingent .ant-card-extra {
  padding: 8px 0px;
}

#AdvertContingent .ant-card-body {
  padding: 8px;
}

#AdvertContingent .card {
  display: inline-block;
  width: 260px;
}

#AdvertContingent .title {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}

#AdvertContingent .number {
  color: #f21850;
  font-size: 20px;
}
